import { ConfigMurucaResourceLayout } from '@net7/boilerplate-muruca';

export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Opera',
  type: 'manuscript',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
      {
        id: 'image-viewer-iiif',
        type: 'viewer-iiif',
        title: 'resource#image-viewer-iiif',
        options: {
          libOptions: {
            window: {
              sideBarOpenByDefault: false,
              allowClose: false,
              allowMaximize: true,
              defaultSideBarPanel: 'info',
              views: [
                { key: 'single' },
                { key: 'gallery' },
              ],
            },
            workspaceControlPanel: {
              enabled: true,
            },
            useDefaultTheme: false,
            'context-menu': false,
            id: 'mirador-container',
          },
        }
      },
    ],
    content: [
      {
        id: 'metadata',
        type: 'metadata',
        title: 'resource#metadata',
      },
      {
        id: 'metadata-contenuti',
        type: 'metadata-dynamic',
        options: {
          accordion: true,
          dynamic: true
        }
      },
      // {
      //   id: 'collection-series',
      //   type: 'collection',
      //   grid: 3,
      //   title: 'Serie collegate',
      // },
      // {
      //   id: 'text-viewer',
      //   type: 'text-viewer',
      // },
      // {
      //   id: 'collection-taxonomies',
      //   type: 'collection',
      //   grid: 3,
      //   title: 'Tassonomie collegate',
      // },
    ],
  },
} as ConfigMurucaResourceLayout;
